<template>
  <div>
    <b-modal
      id="bv-modal-example"
      v-model="modal"
      size="xl"
      hide-footer
      centered
    >
      <template #modal-title>DRAGON TIGERS: {{ client.name }} </template>
      <div>
        <div>
          <div>
            <b-table striped hover :items="games" :fields="fields">
              <template #cell(chanceSimple)="data">
                <vs-button
                  type="button"
                  class="d-inline-flex"
                  relief
                  @click="handleLimitBet('chanceSimple', data.item)"
                >
                  {{ data.item.chanceSimple }}
                </vs-button>
              </template>
              <template #cell(tie)="data">
                <vs-button
                  type="button"
                  class="d-inline-flex"
                  relief
                  @click="handleLimitBet('tie', data.item)"
                >
                  {{ data.item.tie }}
                </vs-button>
              </template>
              <template #cell(perfectTie)="data">
                <vs-button
                  type="button"
                  class="d-inline-flex"
                  relief
                  @click="handleLimitBet('perfectTie', data.item)"
                >
                  {{ data.item.perfectTie }}
                </vs-button>
              </template>
              <template #cell(color)="data">
                <vs-button
                  type="button"
                  class="d-inline-flex"
                  relief
                  @click="handleLimitBet('color', data.item)"
                >
                  {{ data.item.color }}
                </vs-button>
              </template>
              <template #cell(size)="data">
                <vs-button
                  type="button"
                  class="d-inline-flex"
                  relief
                  @click="handleLimitBet('size', data.item)"
                >
                  {{ data.item.size }}
                </vs-button>
              </template>
              <template #cell(parity)="data">
                <vs-button
                  type="button"
                  class="d-inline-flex"
                  relief
                  @click="handleLimitBet('parity', data.item)"
                >
                  {{ data.item.parity }}
                </vs-button>
              </template>
              <template #cell(jackpot)="data">
                <vs-button
                  type="button"
                  class="d-inline-flex"
                  relief
                  @click="handleLimitBet('jackpot', data.item)"
                >
                  {{ data.item.jackpot }}
                </vs-button>
              </template>
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <i class="fa fa-fw fa-bars font-size-16" />
                  </template>
                  <b-dropdown-item
                    @click="edit(data.item)"
                    v-if="Object.keys(client).includes('client')"
                  >
                    <i class="uil-edit m-2 font-size-18" />
                    <span class="align-middle ml-5">Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="configLimits(data.item)">
                    <i class="uil-file-alt m-2 font-size-18" />
                    <span class="align-middle ml-5">Limites</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteGame(data.item)">
                    <i class="uil-trash m-2 font-size-18" />
                    <span class="align-middle ml-5">Eliminar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
    <EditDragonTiger
      ref="EditDragonTiger"
      :game="game"
      :operator="client"
      @closeModal="closeModa"
    />
    <ConfigLimits
      ref="configLimits"
      :game="game"
      :operator="client"
      :currWithLimits="gameWithLimits"
      :currWithOutLimits="gameWithOutLimits"
      :limitsCurrencies="currenciesWithLimits"
      :noLimitsCurrencies="currenciesWithOutLimits"
      :isUpdating="isUpdating"
      :betType="betType"
      @closeModal="closeModa"
      @handleSaveLimits="handleSaveLimits"
    >
    </ConfigLimits>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import EditDragonTiger from '../views/pages/operators/components/dragonTiger/EditDragonTiger.vue';
import ConfigLimits from './ConfigLimits.vue';
import {
  formatLimits,
  fomatLimitBet,
  formatLimitsToSave,
} from '../state/modules/operators';

export default {
  components: { EditDragonTiger, ConfigLimits },
  props: {
    games: {
      type: Array,
    },
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modal: false,
      game: {},
      fields: [
        {
          key: 'name',
          label: 'NAME',
          class: 'text-center',
        },
        {
          key: 'nameOfDragon',
          label: "DRAGON'S NAME",
          class: 'text-center',
        },
        {
          key: 'nameOfTiger',
          label: "TIGER'S NAME",
          class: 'text-center',
        },
        {
          key: 'chanceSimple',
          label: 'CHANCE SIMPLE',
          class: 'text-center',
        },
        {
          key: 'tie',
          label: 'EMPATE',
          class: 'text-center',
        },
        {
          key: 'perfectTie',
          label: 'EMPATE PERFECTO',
          class: 'text-center',
        },
        {
          key: 'size',
          label: 'TAMAÑO',
          class: 'text-center',
        },
        {
          key: 'color',
          label: 'COLOR',
          class: 'text-center',
        },
        {
          key: 'parity',
          label: 'PARIDAD',
          class: 'text-center',
        },
        {
          key: 'jackpot',
          label: 'JACKPOT',
          class: 'text-center',
        },
        {
          key: 'goldenK',
          label: 'K DORADA',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'ACCIONES',
          class: 'text-center',
        },
      ],
      gameWithLimits: [],
      gameWithOutLimits: [],
      isUpdating: false,
      currenciesWithLimits: [],
      currenciesWithOutLimits: [],
      betType: null,
    };
  },
  computed: {
    ...mapGetters({
      success: 'games/isSuccess',
      deleteSuccess: 'dragonTiger/isSuccess',
      operatorSuccess: 'operators/getSuccess',
    }),
  },
  methods: {
    closeModa() {
      this.modal = false;
      this.$emit('updateOperators');
    },
    edit(item) {
      this.game = item;
      this.$refs.EditDragonTiger.modal = true;
    },
    configLimits(game) {
      const { limits: limitsInModel, currencies } = this.client;
      const limits = limitsInModel.filter((limit) => limit.game === game.uuid);

      const {
        gameWithLimits,
        gameWithOutLimits,
        currenciesWithLimits,
        currenciesWithOutLimits,
        isUpdating,
      } = formatLimits(limits, currencies);

      this.gameWithLimits = gameWithLimits;
      this.gameWithOutLimits = gameWithOutLimits;
      this.currenciesWithLimits = currenciesWithLimits;
      this.currenciesWithOutLimits = currenciesWithOutLimits;
      this.isUpdating = isUpdating;
      this.game = game;
      this.betType = null;

      this.$refs.configLimits.modal = true;
    },
    handleLimitBet(betType, game) {
      const {
        gameWithLimits,
        gameWithOutLimits,
        currenciesWithLimits,
        currenciesWithOutLimits,
        isUpdating,
      } = fomatLimitBet(
        this.client.limits,
        this.client.currencies,
        betType,
        game.uuid
      );

      this.gameWithLimits = gameWithLimits;
      this.gameWithOutLimits = gameWithOutLimits;
      this.currenciesWithLimits = currenciesWithLimits;
      this.currenciesWithOutLimits = currenciesWithOutLimits;
      this.isUpdating = isUpdating;
      this.game = game;
      this.betType = betType;
      this.$refs.configLimits.modal = true;
    },
    async handleSaveLimits({ limits, betType, game }) {
      const formatedLimits = formatLimitsToSave(this.client, limits, betType);

      await this.updateLimits({
        limits: formatedLimits,
        operator: this.client,
        game,
      });

      if (!this.operatorSuccess)
        return Swal.fire('Error agregando limites', '', 'info');

      Swal.fire('Limites agregados', '', 'success');
      this.$refs.configLimits.modal = false;
      this.$refs.configLimits.errors = {};
      this.$refs.configLimits.limits = [];
      this.closeModa();
    },
    async deleteGame(game) {
      try {
        const { isConfirmed } = await Swal.fire(
          'Estas seguro en eliminar este juego?',
          '',
          'question'
        );

        if (!isConfirmed) return;

        const { uuid: gameUuid } = game;
        await this.removeGameOfModel({ model: this.client, gameUuid });

        if (!this.deleteSuccess)
          return Swal.fire('Error eliminando el juego', '', 'error');

        Swal.fire('Juego eliminado', '', 'success');
        this.closeModa();
      } catch (error) {
        console.log('ERROR DELETE GAME', error);
      }
    },
    ...mapActions({
      removeGame: 'games/deleteExternalGame',
      removeGameOfModel: 'dragonTiger/deleteGameOfModel', //INFO: MODEL AS CLIENT OR OPERATOR
      updateLimits: 'operators/updateLimits',
    }),
  },
};
</script>
