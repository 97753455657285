<template>
  <b-modal id="bv-modal-example" v-model="modal" size="md" hide-footer centered>
    <h2 class="text-center mb-4">{{ $t('routeMenu.text') }}</h2>
    <div v-if="items.length">
      <div class="d-flex justify-content-center">
        <div v-for="(item, index) in items" :key="index">
          <vs-button type="button" @click="handleEnter(item)">
            {{
              `${item.label} ${
                item.link === $route.path ? `(${$t('helpers.continue')})` : ''
              } `
            }}
          </vs-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    handleEnter(item) {
      this.$emit('handleDirection', item);
    },
  },
};
</script>

<style></style>
