<template>
  <b-modal id="bv-modal-example" v-model="modal" size="md" hide-footer centered>
    <select name="" id="" v-model="currencyId" class="form-select">
      <option
        v-for="currency of currencies"
        :key="currency._id"
        :value="currency"
      >
        {{ currency.name }}
      </option>
    </select>
    <br />
    <div class="container">
      <vs-button @click="send()" :disabled="isDisabled" :loading="isDisabled">
        Configurar
      </vs-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';

export default {
  props: {
    currencies: {
      type: Array,
    },
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    isDisabled() {
      if (this.sended) return true;
      return false;
    },
    ...mapGetters({
      success: 'operators/getSuccess',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors',
    }),
  },
  data() {
    return {
      modal: false,
      currencyId: '',
      sended: false,
    };
  },
  methods: {
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: 'auto',
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, 'warning', 'ERROR', message);
        });
      }
    },
    closeModa() {
      this.modal = false;
    },
    addTag(newTag) {
      const tag = newTag;

      this.value.push(tag);
    },
    async send() {
      this.sended = true;
      await this.addDefaultChips({
        operator: this.operator,
        currency: this.currencyId,
      });

      if (!this.success) {
        this.sended = false;
        return Swal.fire('Error', 'Error agregando fichas', 'error');
      }

      this.checkErrors();
      this.sended = false;
      return Swal.fire('OK', 'Fichas agregadas', 'success');
    },
    ...mapActions({
      addDefaultChips: 'operators/defaultChips',
    }),
  },
};
</script>
