<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Agregar Juego </template>
    <div>
      <form @submit.prevent="save()">
        <div class="mb-3 mx-auto">
          <multiselect
            v-model="value"
            label="name"
            track-by="name"
            :options="games"
            @tag="addTag"
          >
          </multiselect>
        </div>

        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="closeModa()" type="button" success>
            Cancelar
          </vs-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
// import { required } from "vuelidate/lib/validators";

export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    games: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    path: {
      typeof: String,
      default: () => '',
    },
    endpoint: {
      typeof: String,
      default: () => '',
    },
  },
  components: { Multiselect },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
      value: null,
    };
  },
  methods: {
    addTag(newTag) {
      const tag = newTag;

      this.value.push(tag);
    },
    async save() {
      this.typesubmit = true;
      const game = this.value._id;

      const { data } = await this.$http.post(
        `/${this.path}/${this.model._id}/${this.endpoint}`,
        { game }
      );

      if (!data.ok) return Swal.fire('Error adding game', '', 'info');

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: `${this.value.name} agregado con exito`.toUpperCase(),
        showConfirmButton: false,
        timer: 1500,
      });
      this.closeModa();
    },
    defaultValue(value) {
      let roundDuration =
        value.target.options[value.target.options.selectedIndex].getAttribute(
          'data-roundDuration'
        );

      this.typeform.roundDuration = roundDuration;
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
      this.$emit('closeGameMenu');
    },
  },
};
</script>
