<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{
        model.currencies ? $t('addCurrency.modify') : $t('addCurrency.add')
      }}</template
    >
    <div>
      <div>
        <div class="mb-3 mx-auto">
          <multiselect
            v-model="value"
            label="name"
            track-by="name"
            :options="currencies"
            :multiple="true"
            @tag="addTag"
            @remove="remove"
          >
          </multiselect>
        </div>
        <div class="mb-3 mx-auto">
          <div class="d-flex justify-content-center">
            <vs-button type="submit" @click="save" :loading="loading">{{
              $t('form.save')
            }}</vs-button>
            <vs-button @click="closeModa()" success type="button">{{
              $t('form.abort')
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: { Multiselect },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currencies: {
      type: Array,
      default: () => {
        return [];
      },
    },
    path: {
      type: String,
      default: () => '',
    },
    controlDocs: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      //!CURRENCY
      value: [],
      //
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
      fichas: [
        { valor: 0.1 },
        { valor: 0.5 },
        { valor: 1 },
        { valor: 5 },
        { valor: 10 },
      ],

      selectionOpen: false,
      // loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isSuccess: 'currencies/getSuccess',
      clientSuccess: 'clients/isSuccess',
      loading: 'actions/getLoading',
    }),
  },
  methods: {
    async remove(tag) {
      const { uuid } = this.model;
      const { short: currency } = tag;
      await this.removeCurrenciesToModel({ path: this.path, uuid, currency });
    },
    addTag(newTag) {
      const tag = newTag;
      this.value.push(tag);
    },
    async save() {
      this.typesubmit = true;

      if (this.controlDocs) return this.$emit('modifyCurrencies', this.value);

      if (!this.model.currencies.length) return this.addCurrencies();

      this.modifyCurrencies();
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
    },
    async addCurrencies() {
      if (this.model.uuid) {
        const currenciesToAdd = this.value.filter((cr) => cr.uuid);
        await this.addCurrenciesModel({
          path: this.path,
          currencies: currenciesToAdd,
          uuid: this.model.uuid,
        });
      }

      const currencies = this.value.map((currency) => {
        return { currency: currency._id };
      });

      await this.asignCurrencies({
        path: this.path,
        id: this.model._id,
        currencies,
      });

      if (!this.isSuccess)
        return Swal.fire({
          title: 'Error al agregar monedas',
          icon: 'error',
        });

      this.closeModa();
      return Swal.fire({
        title: 'Monedas agregadas',
        icon: 'success',
      });
    },
    async modifyCurrencies() {
      this.toggleLoading();
      const currencies = this.value.map((currency) => {
        return currency._id;
      });

      if (this.model.uuid) {
        const currenciesToAdd = this.value.filter((cr) => cr.uuid);
        await this.addCurrenciesModel({
          path: this.path,
          currencies: currenciesToAdd,
          uuid: this.model.uuid,
        });
      }

      await this.editCurrencies({
        path: this.path,
        id: this.model._id,
        currencies,
      });

      if (!this.isSuccess) {
        this.toggleLoading();
        return Swal.fire({
          title: 'Error al actualizar monedas',
          icon: 'error',
        });
      }

      this.closeModa();
      this.toggleLoading();
      return Swal.fire({
        title: 'Monedas actualizadas',
        icon: 'success',
      });
    },
    formatCurrencies(modelCurrencies) {
      const formattedCurrencies = modelCurrencies?.map((curr) => {
        const currency = this.currencies.find((cr) => cr.name === curr.name);
        return currency;
      });
      return formattedCurrencies;
    },
    ...mapActions({
      asignCurrencies: 'currencies/addCurrencies',
      editCurrencies: 'currencies/modifyCurrencies',
      removeCurrenciesToModel: 'currencies/removeCurrenciesToModel',
      addCurrenciesModel: 'currencies/addCurrenciesToModel',
    }),
    ...mapMutations({
      toggleLoading: 'actions/toggleLoading',
    }),
  },
  watch: {
    model(newValue) {
      if (!Object.entries(this.model).length) return;
      if (!this.currencies.length) return;
      this.value = [];

      const { currencies } = newValue;

      const formatted = this.formatCurrencies(currencies);

      const values = formatted.map((currency) => ({
        name: currency.name,
        _id: currency._id,
        short: currency.short,
        uuid: currency.uuid,
      }));

      this.value.push(...values);
    },
  },
};
</script>

<style>
.multiselect__tag-icon:hover {
  background-color: var(--bs-secondary);
}
</style>
