import {
  BLACK_JACK,
  BLACK_JACK_NAME,
  DRAGON_NAME,
  EXTERNAL_GAMES_NAME,
  ROULETTE,
  ROULETTE_NAME,
  WHEEL_FORTUNE,
  WHEEL_NAME,
} from './CONSTANTS';

export const gameList = [
  {
    name: ROULETTE_NAME,
    path: ROULETTE,
    ref: 'ListRoulette',
    response: ROULETTE,
    type: ROULETTE,
  },
  {
    name: DRAGON_NAME,
    path: 'dragon-tiger',
    ref: 'ListDragonTiger',
    response: 'dragonTigers',
    type: 'dragonTigers',
  },
  {
    name: WHEEL_NAME,
    path: 'wheel-fortune',
    ref: 'ListWheelFortune',
    response: 'games',
    type: WHEEL_FORTUNE,
  },
  {
    name: EXTERNAL_GAMES_NAME,
    path: 'external-games',
    ref: 'ListExternalGames',
    response: 'games',
    type: 'externalGames',
  },
  {
    name: BLACK_JACK_NAME,
    path: BLACK_JACK,
    ref: BLACK_JACK,
    response: 'games',
    type: BLACK_JACK,
  },
];
