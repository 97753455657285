var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"bv-modal-example","size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Editar Dragon Tiger")]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Chance Simple")]),_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.chanceSimple.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.chanceSimple),callback:function ($$v) {_vm.$set(_vm.typeform, "chanceSimple", $$v)},expression:"typeform.chanceSimple"}}),(_vm.typesubmit && _vm.$v.typeform.chanceSimple.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.chanceSimple.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Empate")]),_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.tie.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.tie),callback:function ($$v) {_vm.$set(_vm.typeform, "tie", $$v)},expression:"typeform.tie"}}),(_vm.typesubmit && _vm.$v.typeform.tie.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.tie.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Empate perfecto")]),_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.perfectTie.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.perfectTie),callback:function ($$v) {_vm.$set(_vm.typeform, "perfectTie", $$v)},expression:"typeform.perfectTie"}}),(_vm.typesubmit && _vm.$v.typeform.perfectTie.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.perfectTie.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Color")]),_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.color.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.color),callback:function ($$v) {_vm.$set(_vm.typeform, "color", $$v)},expression:"typeform.color"}}),(_vm.typesubmit && _vm.$v.typeform.color.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.color.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Tamaño")]),_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.size.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.size),callback:function ($$v) {_vm.$set(_vm.typeform, "size", $$v)},expression:"typeform.size"}}),(_vm.typesubmit && _vm.$v.typeform.size.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.size.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Paridad")]),_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.parity.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.parity),callback:function ($$v) {_vm.$set(_vm.typeform, "parity", $$v)},expression:"typeform.parity"}}),(_vm.typesubmit && _vm.$v.typeform.parity.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.parity.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Jackpot")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                  'is-invalid': _vm.typesubmit && _vm.$v.typeform.jackpot.$error,
                },attrs:{"border":""},model:{value:(_vm.typeform.jackpot),callback:function ($$v) {_vm.$set(_vm.typeform, "jackpot", $$v)},expression:"typeform.jackpot"}}),(_vm.typesubmit && _vm.$v.typeform.jackpot.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.jackpot.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("K Dorada")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                  'is-invalid': _vm.typesubmit && _vm.$v.typeform.goldenK.$error,
                },attrs:{"border":""},model:{value:(_vm.typeform.goldenK),callback:function ($$v) {_vm.$set(_vm.typeform, "goldenK", $$v)},expression:"typeform.goldenK"}}),(_vm.typesubmit && _vm.$v.typeform.goldenK.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.goldenK.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])])],1),_c('div',{staticClass:"mb-3 mb-0"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',{attrs:{"type":"submit"}},[_vm._v("Guardar")]),_c('vs-button',{attrs:{"type":"button","success":""},on:{"click":function($event){return _vm.closeModa()}}},[_vm._v(" Cancelar ")])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }