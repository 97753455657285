<template>
  <b-modal id="bv-modal-limits" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      <h5>{{ $t('configLimits.title') }}{{ betType && `: ${betType}` }}</h5>
    </template>

    <b-tabs v-if="currWithLimits.length || currWithOutLimits.length">
      <b-tab active v-if="currWithLimits.length">
        <template v-if="isUpdating" #title>
          {{
            isUpdating
              ? $t('configLimits.withLimits')
              : $t('configLimits.withOutLimits')
          }}
        </template>
        <div class="row mb-4">
          <div class="my-3 row px-2">
            <div class="text-center" :class="betType ? 'col-4' : 'col-3'">
              <strong>{{ $t('configLimits.currency') }}.</strong>
            </div>
            <div class="text-center" :class="betType ? 'col-4' : 'col-3'">
              <strong>{{ $t('configLimits.minBet') }}</strong>
            </div>
            <div class="text-center" :class="betType ? 'col-4' : 'col-3'">
              <strong>{{ $t('configLimits.maxBet') }}</strong>
            </div>
            <div class="col-3 text-center" v-if="!betType">
              <strong>{{ $t('configLimits.betByPosition') }}</strong>
            </div>
          </div>
          <div v-if="currWithLimits.length">
            <form @submit.prevent="save">
              <div v-for="(currency, index) in currWithLimits" :key="index">
                <div class="card p-2">
                  <div class="row align-items-center">
                    <div :class="betType ? 'col-4' : 'col-3'">
                      <p class="m-0">
                        {{ $t('configLimits.currency') }}:
                        <strong>{{ currency.currency }}</strong>
                      </p>
                    </div>
                    <div :class="betType ? 'col-4' : 'col-3'">
                      <vs-input
                        @keyup="(e) => handleChange(e, currency.currency)"
                        :value="currency.minBet ? currency.minBet : 0"
                        type="text"
                        class="shadow-lg"
                        border
                        name="minBet"
                        :placeholder="$t('configLimits.minBet')"
                        minlength="1"
                        required
                      />
                    </div>
                    <div :class="betType ? 'col-4' : 'col-3'">
                      <vs-input
                        @keyup="(e) => handleChange(e, currency.currency)"
                        :value="currency.maxBet ? currency.maxBet : 0"
                        type="text"
                        class="shadow-lg"
                        border
                        name="maxBet"
                        :placeholder="$t('configLimits.maxBet')"
                        minlength="1"
                        required
                      />
                    </div>
                    <div class="col-3" v-if="!betType">
                      <vs-input
                        @keyup="(e) => handleChange(e, currency.currency)"
                        :value="
                          currency.maxBetPosition ? currency.maxBetPosition : 0
                        "
                        type="text"
                        class="shadow-lg"
                        border
                        name="maxBetPosition"
                        :placeholder="$t('configLimits.betByPosition')"
                        minlength="1"
                        required
                      />
                    </div>
                  </div>
                  <vs-button
                    type="button"
                    class="delete-limit"
                    success
                    @click="deleteLimit(currency)"
                    v-if="!betType"
                  >
                    <span class="uil-trash-alt text-light"></span>
                  </vs-button>
                </div>
              </div>
              <div class="mb-3 mx-auto">
                <div class="d-flex justify-content-start">
                  <vs-button type="submit">
                    {{ isUpdating ? $t('helpers.update') : $t('helpers.add') }}
                  </vs-button>
                  <vs-button @click="closeModa()" success type="button">
                    {{ $t('form.abort') }}
                  </vs-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-tab>
      <b-tab title="Monedas sin limites" v-if="currWithOutLimits.length">
        <div class="my-3 row px-2">
          <div class="text-center" :class="betType ? 'col-4' : 'col-3'">
            <strong>{{ $t('configLimits.currency') }}.</strong>
          </div>
          <div class="text-center" :class="betType ? 'col-4' : 'col-3'">
            <strong>{{ $t('configLimits.minBet') }}</strong>
          </div>
          <div class="text-center" :class="betType ? 'col-4' : 'col-3'">
            <strong>{{ $t('configLimits.maxBet') }}</strong>
          </div>
          <div class="col-3 text-center" v-if="!betType">
            <strong>{{ $t('configLimits.betByPosition') }}</strong>
          </div>
        </div>
        <div v-if="currWithOutLimits.length">
          <form @submit.prevent="saveNewLimits">
            <div v-for="(currency, index) in currWithOutLimits" :key="index">
              <div class="card p-2">
                <div class="row align-items-center">
                  <div :class="betType ? 'col-4' : 'col-3'">
                    <p class="m-0">
                      {{ $t('configLimits.currency') }}:
                      <strong>{{ currency.currency }}</strong>
                    </p>
                  </div>
                  <div :class="betType ? 'col-4' : 'col-3'">
                    <vs-input
                      @keyup="(e) => handleUpdate(e, currency.currency)"
                      class="shadow-lg"
                      type="text"
                      border
                      name="minBet"
                      minlength="1"
                      required
                    />
                  </div>
                  <div :class="betType ? 'col-4' : 'col-3'">
                    <vs-input
                      @keyup="(e) => handleUpdate(e, currency.currency)"
                      class="shadow-lg"
                      type="text"
                      border
                      name="maxBet"
                      minlength="1"
                      required
                    />
                  </div>
                  <div class="col-3" v-if="!betType">
                    <vs-input
                      @keyup="(e) => handleUpdate(e, currency.currency)"
                      class="shadow-lg"
                      type="text"
                      border
                      name="maxBetPosition"
                      minlength="1"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 mx-auto">
              <div class="d-flex">
                <vs-button>{{ $t('form.save') }}</vs-button>
                <vs-button @click="closeModa()" success type="button">{{
                  $t('form.abort')
                }}</vs-button>
              </div>
            </div>
          </form>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: [
    'operator',
    'game',
    'currWithLimits',
    'currWithOutLimits',
    'limitsCurrencies',
    'noLimitsCurrencies',
    'isUpdating',
    'betType',
  ],
  data() {
    return {
      modal: false,
      typesubmit: false,
      typeform: {},
      limits: [],
      newLimits: [],
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
    }),
  },
  methods: {
    validateDecimal(valor) {
      let RE = /^\d*(\.\d{1})?\d{0,1}$/;
      if (RE.test(valor)) {
        return true;
      } else {
        return false;
      }
    },
    async deleteLimit({ currency }) {
      const { isConfirmed } = await Swal.fire({
        title: '¿Estas seguro de eliminar este limite?',
        showDenyButton: true,
        denyButtonText: 'No',
        confirmButtonText: 'Si',
        icon: 'info',
        confirmButtonColor: 'primary',
        denyButtonColor: 'secondary',
      });

      if (!isConfirmed) {
        return this.closeModa();
      }

      await this.removeLimit({
        game: this.game,
        operator: this.operator,
        currency,
      });

      if (!this.success) return Swal.fire('Error eliminando limite');

      this.closeModa();
      Swal.fire('Limite eliminado', '', 'success');
    },
    async save() {
      if (Object.entries(this.errors).length) {
        return Swal.fire({
          title: 'Error en el formulario. Verifique por favor',
          html: this.mapErrors(this.errors),
          icon: 'info',
        });
      }

      if (this.betType) return this.handleBetLimit(this.limits);

      await this.updateLimits({
        limits: this.limits,
        operator: this.operator,
        game: this.game,
      });

      if (!this.success)
        return Swal.fire('Error agregando limites', '', 'info');

      Swal.fire('Limites agregados', '', 'success');
      this.closeModa();
    },
    async saveNewLimits() {
      if (Object.entries(this.errors).length) {
        return Swal.fire({
          title: 'Error en el formulario. Verifique por favor',
          html: this.mapErrors(this.errors),
          icon: 'info',
        });
      }

      if (this.betType) return this.handleBetLimit(this.newLimits);

      await this.updateLimits({
        limits: this.newLimits,
        operator: this.operator,
        game: this.game,
      });

      if (!this.success)
        return Swal.fire('Error agregando limites', '', 'info');

      Swal.fire('Limites agregados', '', 'success');
      this.closeModa();
    },
    handleBetLimit(limits) {
      this.$emit('handleSaveLimits', {
        limits,
        betType: this.betType,
        game: this.game,
      });
    },
    closeModa() {
      this.modal = false;
      this.errors = {};
      this.limits = [];
      this.$emit('closeModal');
    },
    handleChange(e, currency) {
      const { value, name } = e.target;

      this.handleErrors(value, name, currency);

      const currencyIndex = this.getIndex(this.limitsCurrencies, currency);

      this.limits[currencyIndex] = {
        ...this.currWithLimits[currencyIndex],
        ...this.limits[currencyIndex],
        currency,
        game: this.game.uuid,
        [name]: value,
      };
    },
    handleUpdate(e, currency) {
      const { value, name } = e.target;

      this.handleErrors(value, name, currency);

      const currencyIndex = this.getIndex(this.noLimitsCurrencies, currency);

      this.newLimits[currencyIndex] = {
        ...this.newLimits[currencyIndex],
        currency,
        game: this.game.uuid,
        [name]: value,
      };
    },
    getIndex(limits, currency) {
      let currencyIndex = 0;
      limits.filter((curr, index) => {
        if (curr === currency) {
          return (currencyIndex = index);
        }
        return '';
      });
      return currencyIndex;
    },
    handleErrors(value, name, currency) {
      if (!value) return;

      if (!this.validateDecimal(value)) {
        this.errors[currency] = {
          ...this.errors[currency],
          [name]: value,
        };
      } else {
        if (this.errors[currency]) {
          delete this.errors[currency][name];

          if (!Object.entries(this.errors[currency]).length) {
            delete this.errors[currency];
          }
        }
      }
    },
    mapErrors(errors) {
      let message = '';
      for (let error in errors) {
        for (let detail in errors[error]) {
          const value = errors[error][detail];
          message += `<strong>${detail}: </strong>${value}</br>`;
        }
      }

      return message;
    },
    ...mapActions({
      updateLimits: 'operators/updateLimits',
      removeLimit: 'operators/removeLimit',
    }),
  },
};
</script>

<style scoped>
.delete-limit {
  position: absolute;
  right: -10px;
  top: 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}
</style>
